import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { UserLoginService } from "../services/user.service";
import { LoggedInUserInfo } from '../model/LoggedInUserInfo';

@Component({
    selector: 'app-chat-sidebar',
    templateUrl: './chat-sidebar.component.html',
    styleUrls: ['./chat-sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
    public isCollapsed = true;
    private lastPoppedUrl: string;
    private yScrollStack: number[] = [];
    filter: any;
    userName: any;
    emailId: any;
    userInfo: LoggedInUserInfo;
    dashboardSelected: boolean = false;
    calendarSelected: boolean = false;
    studentSelected: boolean = false;
    teacherSelected: boolean = false;
    accountSelected: boolean = false;
   


    selectDashboard() {
        this.dashboardSelected = true;
        this.calendarSelected = false;
        this.studentSelected = false;
        this.teacherSelected = false;
        this.accountSelected = false;
      }
    selectCalendar() {
        this.dashboardSelected = false;
        this.calendarSelected = true;
        this.studentSelected = false;
        this.teacherSelected = false;
        this.accountSelected = false;
      }

    selectStudent() {
        this.dashboardSelected = false;
        this.calendarSelected = false;
        this.studentSelected = true;
        this.teacherSelected = false;
        this.accountSelected = false;
      }
      selectTeacher() {
        this.dashboardSelected = false;
        this.calendarSelected = false;
        this.studentSelected = false;
        this.teacherSelected = true;
        this.accountSelected = false;
      }
      selectAccount() {
        this.dashboardSelected = false;
        this.calendarSelected = false;
        this.studentSelected = false;
        this.teacherSelected = false;
        this.accountSelected = true;
      }
      
    constructor(public location: Location, private router: Router,
        private userService: UserLoginService) {
    }

    ngOnInit() {
        // this.dashboardSelected = true;
        this.userInfo = this.userService.getUserInfo();
        // console.log("USERINFOR ==>", this.userInfo);
        if(this.userInfo != null){
            this.userName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
            this.emailId = this.userInfo.emailId;
        }
      this.router.events.subscribe((event) => {
        this.isCollapsed = true;
        if (event instanceof NavigationStart) {
           if (event.url != this.lastPoppedUrl)
               this.yScrollStack.push(window.scrollY);
       } else if (event instanceof NavigationEnd) {
           if (event.url == this.lastPoppedUrl) {
               this.lastPoppedUrl = undefined;
               window.scrollTo(0, this.yScrollStack.pop());
           } else
               window.scrollTo(0, 0);
       }
     });
     this.location.subscribe((ev:PopStateEvent) => {
         this.lastPoppedUrl = ev.url;
     });
    }

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());

        if( titlee === 'home' ) {
            return true;
        }
        else {
            return false;
        }
    }
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === 'documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }

    sidebaroc() {
        if(document.getElementById("side-barsb").style.display == "block"){
            document.getElementById("side-barsb").style.display = "table";
            document.getElementById("showsb").style.visibility = "visible";
            document.getElementById("showsb").style.display = "table-cell";
            document.getElementById("show2sb").style.visibility = "hidden";
            document.getElementById("show2sb").style.display = "none";
            document.getElementById("profilesb").style.display = "none";
            document.getElementById("screen").style.marginLeft = "60px";
        }
        else 
        {   
            document.getElementById("side-barsb").style.display = "block";
            document.getElementById("showsb").style.visibility = "hidden";
            document.getElementById("show2sb").style.visibility = "visible";
            document.getElementById("show2sb").style.display = "table-cell";
            document.getElementById("profilesb").style.display = "table";
            document.getElementById("screen").style.marginLeft = "250px";

            }
    }

    loggedOut() {
        if (this.userService.isAuthenticated()) {
            this.userService.logout();
            this.router.navigate(["/home"]);
            // this.userName='null';
        }
    }
}
