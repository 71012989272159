import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentService } from '../services/payment.service';
import { LanguageService } from '../services/language.service';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../services/constants';
import { UsersService } from '../services/users.service';
import { UserLoginService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  today: Date;
  minDob: Date;
  maxDob: Date;
  constructor(
        private spinner: NgxSpinnerService,
        private router: Router,
        private paymentService: PaymentService,
        private languageService: LanguageService,
        private toast: ToastrService,
        private constants:Constants,
        private userService:UsersService,
        private authService: UserLoginService
        
    
  ) { 
    this.today = new Date();
    this.minDob = new Date();
    this.minDob.setFullYear(this.today.getFullYear() - 5);
    this.maxDob = new Date();
    this.maxDob.setFullYear(this.today.getFullYear() - 70);
  }

  studentExists = false
  email = "";
  firstName = "";
  lastName = "";
  school = "";
  grade = "";
  dob = "";
  password = "";
  phone=undefined;
  countryCodes = this.constants.COUNTRIES_LIST
  selectedCode = '+971';
  otp="";
  showOtp = false;
 


  ngOnInit(): void {
  }

  isArabic(): boolean {
    return this.languageService.getLanguage() === 'Arabic'; // Adjust to match your service's method for checking language
  }
  async submitForm (form: any) {
    if(form.invalid) {
      return;
    }
    console.log(form.value);
    const resigtrationForm = {
      email: form.value.Email,
      password: form.value.Password,
      firstName: form.value.FirstName,
      lastName: form.value.LastName,
      phone: `${this.selectedCode}${form.value.Phone}`,
      dob: form.value.Dob,
      grade: form.value.Grade,
      school: form.value.School
    }
    try{
      this.spinner.show();
      const res = await this.userService.registerUser(resigtrationForm);
      console.log("response from register user", res);
      this.showOtp = true;
    }catch(err){
      const errorMessage = err?.message || 'An unknown error occurred';
      console.error("Error while registering user", errorMessage);
      this.toast.error(`Registration failed: ${errorMessage}`, "FAILED");
    }finally{
      this.spinner.hide();
    }
    
  }

  async checkStudent(paymentForm: any) {
    try {
      this.spinner.show();
      const student: any = await this.paymentService.getStudentInfo(this.email);
      console.log("fetched Student details:", student);

      if (student?.data.length > 0) {

        // const firstName = student?.data[0].firstName.trim().toLowerCase();
        // const lastName = student?.data[0].lastName.trim().toLowerCase();
        
        // if (firstName === this.firstName.trim().toLowerCase() &&
        //   lastName === this.lastName.trim().toLowerCase()) {
        //   this.toast.info(
        //     "Looks like you already have an account with us, please use existing credential to login after completing the payment",
        //     "EXISTS",
        //     {
        //       timeOut: 10000
        //     }
        //   );
        //   this.studentExists = false;
        //   paymentForm.controls.Email.setErrors(null);
        //   this.grade = student?.data[0].grade;
        //   this.dob = student?.data[0].doB;
        //   this.school = student?.data[0].schoolName;
        // } else {
          this.studentExists = true;
          paymentForm.controls.Email.setErrors({ studentExists: true });
        // }


      } else {
        this.studentExists = false;
        paymentForm.controls.Email.setErrors(null);

      }

    } catch (err) {
      console.error("Error while getting studentInfo", err.message);
    } finally {
      this.spinner.hide()
    }
  }

  confirmOtp = async (otp: any) => {
    try {
      this.spinner.show();
      console.log("otp", otp);
      const res = await this.authService.confirmOtp(this.email, otp.toString());
      console.log("response from confirm otp", res);
     if(res){
      this.toast.success("OTP verified successfully", "SUCCESS", {timeOut: 10000});
      this.router.navigate(['/login']);
     }else{
      this.toast.error("OTP verification failed", "FAILED", {
        timeOut: 5000
      });
     }
      
    } catch (err) {
      console.error("Error while confirming otp", err.message);
      
      this.toast.error("OTP verification failed", "FAILED", {
        timeOut: 5000
      });
    } finally {
      this.spinner.hide();
    }
  }
}
